// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_header__g0r0E {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-size: cover;
  position: relative;
  overflow: hidden;
  z-index: -100;
}

.home_headerText__R3Sfk {
  position: absolute;
  top: 30%;
  left: 10%;
  text-align: left;
  font-size: 30px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.home_headerText__R3Sfk h1 {
  font-size: 60px;
  margin-top: 20px;
}

.home_headerText__R3Sfk span {
  color: #90e0ef;
}

@media screen and (max-width: 600px) {
  .home_header__g0r0E {
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
  }

  .home_headerText__R3Sfk {
    text-align: left;
    font-size: 20px;
    color: #fff; /* Text color */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .home_headerText__R3Sfk h1 {
    font-size: 30px;
    margin-top: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/home/home.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,UAAU;EACV,SAAS;EACT,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,yCAAyC;AAC3C;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,WAAW;IACX,aAAa;IACb,UAAU;IACV,SAAS;IACT,sBAAsB;IACtB,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,WAAW,EAAE,eAAe;IAC5B,2CAA2C;EAC7C;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;AACF","sourcesContent":[".header {\n  width: 100%;\n  height: 100vh;\n  padding: 0;\n  margin: 0;\n  background-size: cover;\n  position: relative;\n  overflow: hidden;\n  z-index: -100;\n}\n\n.headerText {\n  position: absolute;\n  top: 30%;\n  left: 10%;\n  text-align: left;\n  font-size: 30px;\n  color: #fff;\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);\n}\n\n.headerText h1 {\n  font-size: 60px;\n  margin-top: 20px;\n}\n\n.headerText span {\n  color: #90e0ef;\n}\n\n@media screen and (max-width: 600px) {\n  .header {\n    width: 100%;\n    height: 100vh;\n    padding: 0;\n    margin: 0;\n    background-size: cover;\n    background-position: center;\n    position: relative;\n    overflow: hidden;\n  }\n\n  .headerText {\n    text-align: left;\n    font-size: 20px;\n    color: #fff; /* Text color */\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n  }\n\n  .headerText h1 {\n    font-size: 30px;\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `home_header__g0r0E`,
	"headerText": `home_headerText__R3Sfk`
};
export default ___CSS_LOADER_EXPORT___;
