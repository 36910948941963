// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav_navbar__4sQ6A {
  background-color: #2d5d83;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_name__rUeQ6 {
  color: white;
  margin-left: 10px;
  font-size: 30px;
}

.nav_dot__VUYXj {
  color: #90e0ef;
}

.nav_clickables__mMnJW {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .nav_clickables__mMnJW {
    font-size: 13px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/nav/nav.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,MAAM;EACN,WAAW;EACX,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;IACf,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,mBAAmB;EACrB;AACF","sourcesContent":[".navbar {\n  background-color: #2d5d83;\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: 1000;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.name {\n  color: white;\n  margin-left: 10px;\n  font-size: 30px;\n}\n\n.dot {\n  color: #90e0ef;\n}\n\n.clickables {\n  height: 50px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n@media screen and (max-width: 600px) {\n  .clickables {\n    font-size: 13px;\n    height: 50px;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `nav_navbar__4sQ6A`,
	"name": `nav_name__rUeQ6`,
	"dot": `nav_dot__VUYXj`,
	"clickables": `nav_clickables__mMnJW`
};
export default ___CSS_LOADER_EXPORT___;
