// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section_scrollTo__NEmiA {
  cursor: pointer;
  color: #ffffff;
  margin-right: 20px;
  position: relative;
  display: inline-block;
}

.section_scrollTo__NEmiA:hover::after {
  right: 0;
}

.section_scrollTo__NEmiA::after {
  content: '';
  position: absolute;
  left: 0;
  right: 100%;
  bottom: -5px;
  height: 2px;
  background-color: #90e0ef;
  transition: right 0.3s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/section/section.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,WAAW;EACX,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,2BAA2B;AAC7B","sourcesContent":[".scrollTo {\n  cursor: pointer;\n  color: #ffffff;\n  margin-right: 20px;\n  position: relative;\n  display: inline-block;\n}\n\n.scrollTo:hover::after {\n  right: 0;\n}\n\n.scrollTo::after {\n  content: '';\n  position: absolute;\n  left: 0;\n  right: 100%;\n  bottom: -5px;\n  height: 2px;\n  background-color: #90e0ef;\n  transition: right 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollTo": `section_scrollTo__NEmiA`
};
export default ___CSS_LOADER_EXPORT___;
