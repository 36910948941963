// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project_card__PX4qr {
  margin: 10px;
}

.project_image__PSJW5 {
  object-fit: cover;
  filter: blur(0.7px);
  transition: filter 0.3s;
}

.project_image__PSJW5:hover {
  filter: blur(0); /* Remove blur on hover */
}

.project_title__uLY7W {
  font-size: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.project_desc__KQGpE {
  text-align: left;
}

.project_buttonsContainer__KpF5x {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
}

/* ----------------SECTION CSS--------------------- */

.project_sectionTitle__qGoPr {
  justify-content: center;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  font-size: 50px;
  margin-bottom: 100px;
}

.project_container__rSZXv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
  height: 1200px;
}

.project_carouselContainer__ldmKL {
  width: 95%;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Projects/project.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe,EAAE,yBAAyB;AAC5C;;AAEA;EACE,eAAe;EACf,2CAA2C;EAC3C,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;AACV;;AAEA,qDAAqD;;AAErD;EACE,uBAAuB;EACvB,cAAc;EACd,2CAA2C;EAC3C,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,cAAc;AAChB","sourcesContent":[".card {\n  margin: 10px;\n}\n\n.image {\n  object-fit: cover;\n  filter: blur(0.7px);\n  transition: filter 0.3s;\n}\n\n.image:hover {\n  filter: blur(0); /* Remove blur on hover */\n}\n\n.title {\n  font-size: 30px;\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);\n  text-align: left;\n}\n\n.desc {\n  text-align: left;\n}\n\n.buttonsContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  gap: 5px;\n}\n\n/* ----------------SECTION CSS--------------------- */\n\n.sectionTitle {\n  justify-content: center;\n  color: #ffffff;\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);\n  font-size: 50px;\n  margin-bottom: 100px;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  position: relative;\n  text-align: center;\n  height: 1200px;\n}\n\n.carouselContainer {\n  width: 95%;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `project_card__PX4qr`,
	"image": `project_image__PSJW5`,
	"title": `project_title__uLY7W`,
	"desc": `project_desc__KQGpE`,
	"buttonsContainer": `project_buttonsContainer__KpF5x`,
	"sectionTitle": `project_sectionTitle__qGoPr`,
	"container": `project_container__rSZXv`,
	"carouselContainer": `project_carouselContainer__ldmKL`
};
export default ___CSS_LOADER_EXPORT___;
